@import url("https://use.typekit.net/rjk3aci.css");

body{
  background-color: #ffffff;
}

h1, p, img{
  margin: 0 auto;
  padding: 0px;
}

.splashPage{
  text-align: center;
  background-image: url('assets/bg_pikachu.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &.bg_pokeball{
    background-image: url('assets/bg_pokeball.png');
  }
  &.bg_pikachu{
    background-image: url('assets/bg_pikachu.png');
  }
  &.bg_snorlax{
    background-image: url('assets/bg_snorlax.png');
  }
  &.bg_cphfs{
    background-image: url('assets/bg_cphfs.jpg');
  }
}

.background{
  // display: none;
}

.slide_top, .slide_bottom{
  background-color: #ffffff;
  height: 50vh;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
}


.slide_top{
  top:0;
  z-index: 1;
  animation: slideUp 0.75s ease-out 3s forwards;
  &.ganni_slide_top{
    // padding-top: 20px;
    animation: ganniSlideUp 0.75s ease-out 3s forwards;
  }
  .logos img{
    position: absolute;
    left: 0;
    right: 0;

    &.logo_levis{
      animation: leviSlideIn 5s ease-out forwards;
    }
    &.logo_pokemon{
      width: 50%;
      top: 35%;
      animation: pokemonSlideIn 5s ease-out forwards;
    }
    &.ganni_logo_levis{
      animation: ganniLeviSlideIn 5s ease-out forwards;
    }
    &.ganni_x{
      width:4%;
      opacity: 0;
      margin-top: 25px;
      // margin-left: 117px;
      animation: fadeIn 1.25s ease-out 3.6s forwards;
    }
    &.ganni_logo_ganni{
      width: 40%;
      animation: ganniSlideIn 5s ease-out forwards;
    }
  }
}

.slide_bottom{
  bottom:0;
  animation: slideDown 0.75s ease-out 3s forwards;
  &.ganni{
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    p{
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }
  }
  h1{
    text-transform: uppercase;
    width: 70%;
    opacity: 0;
    padding:20px 20px 0px 20px;
    margin-top: 100px;
    font-size: 28px;
    color: #333333;
    animation: headerFadeIn 5s ease-out forwards;
    font-family: interstate,sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  img{
    opacity: 0;
    margin:10px auto;
    width: 65%;
    animation: fadeIn 0.75s ease-out 3.75s forwards;
  }
  p{
    opacity: 0;
    font-size: 24px;
    margin:10px auto;
    color: #7C7C7C;
    animation: fadeIn 0.75s ease-out 3.75s forwards;
    font-family: interstate,sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}


@keyframes leviSlideIn{
  0% {transform: translateY(-350px); width: 32%;}
  15% {transform: translateY(175px); width: 32%;}
  60% {transform: translateY(175px); width: 32%;}
  75%  {transform: translateY(10px); width: 26%;}
  100%  {transform: translateY(10px); width: 26%;}
}
@keyframes pokemonSlideIn{
  0% {transform: translateY(-200px); width: 55%;}
  15% {transform: translateY(110px); width: 55%;}
  60% {transform: translateY(110px); width: 55%;}
  75%  {transform: translateY(15px); width: 45%;}
  100%  {transform: translateY(15px); width: 45%;}
}
@keyframes ganniLeviSlideIn{
  0% {transform: translate(0px, -350px); width: 40%;}
  15% {transform: translate(0px, 175px); width: 40%;}
  60% {transform: translate(0px, 175px); width: 40%;}
  75%  {transform: translate(-95px, 15px); width: 26%;}
  100%  {transform: translate(-95px, 15px); width: 26%;}
}
@keyframes ganniX{
  0% {transform: translate(0px, -250px);}
  15% {transform: translate(0px, 260px);}
  60% {transform: translate(0px, 260px);}
  75%  {transform: translate(0px, 25px);}
  100%  {transform: translate(0px, 25px);}
}
@keyframes ganniSlideIn{
  0% {transform: translate(0px, -350px); width: 38%;}
  15% {transform: translate(0px, 260px); width: 38%;}
  60% {transform: translate(0px, 260px); width: 38%;}
  75%  {transform: translate(80px, 20px); width: 30%;}
  100%  {transform: translate(80px, 20px); width: 30%;}
}
@keyframes headerFadeIn{
  0% {opacity: 0; margin-top: 55px;}
  10% {opacity: 0; margin-top: 55px;}
  25% {opacity: 100; margin-top: 55px;}
  60% {opacity: 100; margin-top: 55px;}
  75% {opacity: 100; margin-top: 0px;}
  100% {opacity: 100; margin-top: 0px;}
}
@keyframes fadeIn{
  0% {opacity: 0;}
  100% {opacity: 100;}
}
@keyframes slideUp{
  100% {height: 70px;}
}
@keyframes ganniSlideUp{
  100% {height: 70px;}
}
@keyframes slideDown{
  100% {height: 155px;}
}
