@import url(https://use.typekit.net/rjk3aci.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #ffffff; }

h1, p, img {
  margin: 0 auto;
  padding: 0px; }

.splashPage {
  text-align: center;
  background-image: url(/static/media/bg_pikachu.5b66a359.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .splashPage.bg_pokeball {
    background-image: url(/static/media/bg_pokeball.8154fbf5.png); }
  .splashPage.bg_pikachu {
    background-image: url(/static/media/bg_pikachu.5b66a359.png); }
  .splashPage.bg_snorlax {
    background-image: url(/static/media/bg_snorlax.57529177.png); }
  .splashPage.bg_cphfs {
    background-image: url(/static/media/bg_cphfs.ea6f0293.jpg); }

.slide_top, .slide_bottom {
  background-color: #ffffff;
  height: 50vh;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  right: 0; }

.slide_top {
  top: 0;
  z-index: 1;
  -webkit-animation: slideUp 0.75s ease-out 3s forwards;
          animation: slideUp 0.75s ease-out 3s forwards; }
  .slide_top.ganni_slide_top {
    -webkit-animation: ganniSlideUp 0.75s ease-out 3s forwards;
            animation: ganniSlideUp 0.75s ease-out 3s forwards; }
  .slide_top .logos img {
    position: absolute;
    left: 0;
    right: 0; }
    .slide_top .logos img.logo_levis {
      -webkit-animation: leviSlideIn 5s ease-out forwards;
              animation: leviSlideIn 5s ease-out forwards; }
    .slide_top .logos img.logo_pokemon {
      width: 50%;
      top: 35%;
      -webkit-animation: pokemonSlideIn 5s ease-out forwards;
              animation: pokemonSlideIn 5s ease-out forwards; }
    .slide_top .logos img.ganni_logo_levis {
      -webkit-animation: ganniLeviSlideIn 5s ease-out forwards;
              animation: ganniLeviSlideIn 5s ease-out forwards; }
    .slide_top .logos img.ganni_x {
      width: 4%;
      opacity: 0;
      margin-top: 25px;
      -webkit-animation: fadeIn 1.25s ease-out 3.6s forwards;
              animation: fadeIn 1.25s ease-out 3.6s forwards; }
    .slide_top .logos img.ganni_logo_ganni {
      width: 40%;
      -webkit-animation: ganniSlideIn 5s ease-out forwards;
              animation: ganniSlideIn 5s ease-out forwards; }

.slide_bottom {
  bottom: 0;
  -webkit-animation: slideDown 0.75s ease-out 3s forwards;
          animation: slideDown 0.75s ease-out 3s forwards; }
  .slide_bottom.ganni {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px; }
    .slide_bottom.ganni p {
      font-weight: bold;
      font-size: 20px;
      color: #333333; }
  .slide_bottom h1 {
    text-transform: uppercase;
    width: 70%;
    opacity: 0;
    padding: 20px 20px 0px 20px;
    margin-top: 100px;
    font-size: 28px;
    color: #333333;
    -webkit-animation: headerFadeIn 5s ease-out forwards;
            animation: headerFadeIn 5s ease-out forwards;
    font-family: interstate,sans-serif;
    font-weight: 700;
    font-style: normal; }
  .slide_bottom img {
    opacity: 0;
    margin: 10px auto;
    width: 65%;
    -webkit-animation: fadeIn 0.75s ease-out 3.75s forwards;
            animation: fadeIn 0.75s ease-out 3.75s forwards; }
  .slide_bottom p {
    opacity: 0;
    font-size: 24px;
    margin: 10px auto;
    color: #7C7C7C;
    -webkit-animation: fadeIn 0.75s ease-out 3.75s forwards;
            animation: fadeIn 0.75s ease-out 3.75s forwards;
    font-family: interstate,sans-serif;
    font-weight: 500;
    font-style: normal; }

@-webkit-keyframes leviSlideIn {
  0% {
    -webkit-transform: translateY(-350px);
            transform: translateY(-350px);
    width: 32%; }
  15% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px);
    width: 32%; }
  60% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px);
    width: 32%; }
  75% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    width: 26%; }
  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    width: 26%; } }

@keyframes leviSlideIn {
  0% {
    -webkit-transform: translateY(-350px);
            transform: translateY(-350px);
    width: 32%; }
  15% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px);
    width: 32%; }
  60% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px);
    width: 32%; }
  75% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    width: 26%; }
  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    width: 26%; } }

@-webkit-keyframes pokemonSlideIn {
  0% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    width: 55%; }
  15% {
    -webkit-transform: translateY(110px);
            transform: translateY(110px);
    width: 55%; }
  60% {
    -webkit-transform: translateY(110px);
            transform: translateY(110px);
    width: 55%; }
  75% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    width: 45%; }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    width: 45%; } }

@keyframes pokemonSlideIn {
  0% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    width: 55%; }
  15% {
    -webkit-transform: translateY(110px);
            transform: translateY(110px);
    width: 55%; }
  60% {
    -webkit-transform: translateY(110px);
            transform: translateY(110px);
    width: 55%; }
  75% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    width: 45%; }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    width: 45%; } }

@-webkit-keyframes ganniLeviSlideIn {
  0% {
    -webkit-transform: translate(0px, -350px);
            transform: translate(0px, -350px);
    width: 40%; }
  15% {
    -webkit-transform: translate(0px, 175px);
            transform: translate(0px, 175px);
    width: 40%; }
  60% {
    -webkit-transform: translate(0px, 175px);
            transform: translate(0px, 175px);
    width: 40%; }
  75% {
    -webkit-transform: translate(-95px, 15px);
            transform: translate(-95px, 15px);
    width: 26%; }
  100% {
    -webkit-transform: translate(-95px, 15px);
            transform: translate(-95px, 15px);
    width: 26%; } }

@keyframes ganniLeviSlideIn {
  0% {
    -webkit-transform: translate(0px, -350px);
            transform: translate(0px, -350px);
    width: 40%; }
  15% {
    -webkit-transform: translate(0px, 175px);
            transform: translate(0px, 175px);
    width: 40%; }
  60% {
    -webkit-transform: translate(0px, 175px);
            transform: translate(0px, 175px);
    width: 40%; }
  75% {
    -webkit-transform: translate(-95px, 15px);
            transform: translate(-95px, 15px);
    width: 26%; }
  100% {
    -webkit-transform: translate(-95px, 15px);
            transform: translate(-95px, 15px);
    width: 26%; } }

@-webkit-keyframes ganniX {
  0% {
    -webkit-transform: translate(0px, -250px);
            transform: translate(0px, -250px); }
  15% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px); }
  60% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px); }
  75% {
    -webkit-transform: translate(0px, 25px);
            transform: translate(0px, 25px); }
  100% {
    -webkit-transform: translate(0px, 25px);
            transform: translate(0px, 25px); } }

@keyframes ganniX {
  0% {
    -webkit-transform: translate(0px, -250px);
            transform: translate(0px, -250px); }
  15% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px); }
  60% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px); }
  75% {
    -webkit-transform: translate(0px, 25px);
            transform: translate(0px, 25px); }
  100% {
    -webkit-transform: translate(0px, 25px);
            transform: translate(0px, 25px); } }

@-webkit-keyframes ganniSlideIn {
  0% {
    -webkit-transform: translate(0px, -350px);
            transform: translate(0px, -350px);
    width: 38%; }
  15% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px);
    width: 38%; }
  60% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px);
    width: 38%; }
  75% {
    -webkit-transform: translate(80px, 20px);
            transform: translate(80px, 20px);
    width: 30%; }
  100% {
    -webkit-transform: translate(80px, 20px);
            transform: translate(80px, 20px);
    width: 30%; } }

@keyframes ganniSlideIn {
  0% {
    -webkit-transform: translate(0px, -350px);
            transform: translate(0px, -350px);
    width: 38%; }
  15% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px);
    width: 38%; }
  60% {
    -webkit-transform: translate(0px, 260px);
            transform: translate(0px, 260px);
    width: 38%; }
  75% {
    -webkit-transform: translate(80px, 20px);
            transform: translate(80px, 20px);
    width: 30%; }
  100% {
    -webkit-transform: translate(80px, 20px);
            transform: translate(80px, 20px);
    width: 30%; } }

@-webkit-keyframes headerFadeIn {
  0% {
    opacity: 0;
    margin-top: 55px; }
  10% {
    opacity: 0;
    margin-top: 55px; }
  25% {
    opacity: 100;
    margin-top: 55px; }
  60% {
    opacity: 100;
    margin-top: 55px; }
  75% {
    opacity: 100;
    margin-top: 0px; }
  100% {
    opacity: 100;
    margin-top: 0px; } }

@keyframes headerFadeIn {
  0% {
    opacity: 0;
    margin-top: 55px; }
  10% {
    opacity: 0;
    margin-top: 55px; }
  25% {
    opacity: 100;
    margin-top: 55px; }
  60% {
    opacity: 100;
    margin-top: 55px; }
  75% {
    opacity: 100;
    margin-top: 0px; }
  100% {
    opacity: 100;
    margin-top: 0px; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100; } }

@-webkit-keyframes slideUp {
  100% {
    height: 70px; } }

@keyframes slideUp {
  100% {
    height: 70px; } }

@-webkit-keyframes ganniSlideUp {
  100% {
    height: 70px; } }

@keyframes ganniSlideUp {
  100% {
    height: 70px; } }

@-webkit-keyframes slideDown {
  100% {
    height: 155px; } }

@keyframes slideDown {
  100% {
    height: 155px; } }

